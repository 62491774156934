import React from "react"
import { VisitorCenter } from "../../models/NationalPark"
import { Helpers } from "../../services/Helpers"
import SimpleInternalButton from "../buttons/SimpleInternalButton"
import VisitorCenterCard from "../cards/VisitorCenterCard"
import Container from "../layout/Container"
import WideContainer from "../layout/WideContainer"

interface Props {
  title: string
  subtitle: string
  centers: VisitorCenter[]
  parkname: string
}

const VisitorCenterComponent: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  centers,
  parkname,
}) => {
  return (
    <>
      {centers.length > 0 && (
        <WideContainer center={false} className="px-4">
          <SimpleInternalButton
            text={`See all`}
            link={`/${Helpers.sanitizeUrl(parkname)}/visitor-centers/`}
            className="absolute right-28 mt-0 invisible md:visible"
          />
          <h2 className="mb-0 mt-8">{title}</h2>
          <Container center={false} padding={false}>
            <p className="mt-2">{subtitle}</p>
          </Container>
          <div className="grid grid-cols-1 md:grid-cols-3">
            {centers.map((centre, index) => {
              return (
                <VisitorCenterCard
                  key={index.toString()}
                  center={centre}
                  parkname={parkname}
                />
              )
            })}
          </div>
        </WideContainer>
      )}
    </>
  )
}

export default VisitorCenterComponent
