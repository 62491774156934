import React from "react"
import { BiPhoneCall, BiTime } from "react-icons/bi"
import Colors from "../../constants/Colors"
import { VisitorCenter } from "../../models/NationalPark"
import CustomCard from "../cards/CustomCard"

interface Props {
  title: string
  visitorCenter: VisitorCenter
}

export default function VisitorCenterSidebar({ title, visitorCenter }: Props) {
  return (
    <div className="col-span-12 md:col-span-4 pr-6">
      <CustomCard className="pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
        <h4 className="mt-0 mb-3">{title}</h4>
        {visitorCenter.center_phone.length > 0 && (
          <div className="bg-lightest rounded-sm pt-2 pb-1 px-2">
            <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
              Phone
            </p>
            <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-36 font-bold">
              <BiPhoneCall
                size={14}
                className="inline-block"
                color={Colors.themeColor}
                style={{ marginBottom: 2, marginRight: 5 }}
              />
              {visitorCenter.center_phone[0].text}
            </p>
          </div>
        )}
        {visitorCenter.center_opening_hours.length > 0 && (
          <div className="rounded-sm pt-2 pb-1 px-2">
            <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
              Opening Hours
            </p>
            <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-36 font-bold">
              <BiTime
                size={14}
                className="inline-block"
                color={Colors.themeColor}
                style={{ marginBottom: 2, marginRight: 5 }}
              />
              {visitorCenter.center_opening_hours[0].text}
            </p>
          </div>
        )}
      </CustomCard>
    </div>
  )
}
